import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.menuTarget.classList.add("hidden")
  }

  toggle(event) {
    console.log("clicked!")
    this.menuTarget.classList.toggle("hidden");
  }
}
